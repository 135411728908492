<template>
  <div id="ErrorPage">
    <div class="icon">404</div>
    <h2>頁面不存在</h2>
    <p>您所查詢的網頁不存在或已移除</p>
    <router-link to="/">回到首頁</router-link>
  </div>
</template>

<script>
export default {
  name: 'PayError',
  created() {
    var meta = document.createElement('meta');
    meta.setAttribute('name', 'prerender-status-code');
    meta.content = '404';
    document.getElementsByTagName('head')[0].appendChild(meta);
    this.meta_title = '頁面不存在｜欣富通｜SIFUTON';
    this.meta_content =
      '您查詢的頁面已移除或是不存在，您可以返回銳仕技研官方網站首頁查看其他頁面，有任何疑問請與我們聯繫。';
    this.meta_cover = window.location.origin + '/img/share/index.jpg';
    window.prerenderReady = true;
  },
  metaInfo() {
    return {
      title: '頁面不存在｜欣富通｜SIFUTON',
      meta: [
        {
          property: 'og:description',
          content:
            '您查詢的頁面已移除或是不存在，您可以返回銳仕技研官方網站首頁查看其他頁面，有任何疑問請與我們聯繫。',
          vmid: 'og:description',
        },
        {
          property: 'twitter:description',
          content:
            '您查詢的頁面已移除或是不存在，您可以返回銳仕技研官方網站首頁查看其他頁面，有任何疑問請與我們聯繫。',
          vmid: 'twitter:description',
        },
        {
          property: 'description',
          content:
            '您查詢的頁面已移除或是不存在，您可以返回銳仕技研官方網站首頁查看其他頁面，有任何疑問請與我們聯繫。',
          vmid: 'description',
        },
        {
          property: 'og:image',
          content: 'https://www.sifuton.com.tw/img/share/index.jpg',
          vmid: 'og:image',
        },
        {
          property: 'apple-mobile-web-app-title',
          content: '頁面不存在｜欣富通｜SIFUTON',
          vmid: 'apple-mobile-web-app-title',
        },
        {
          property: 'application-name',
          content: '頁面不存在｜欣富通｜SIFUTON',
          vmid: 'application-name',
        },
        {
          property: 'og:site_name',
          content: '頁面不存在｜欣富通｜SIFUTON',
          vmid: 'og:site_name',
        },
        {
          property: 'og:type',
          content: 'website',
          vmid: 'og:type',
        },
        {
          property: 'og:locale',
          content: 'zh_tw',
          vmid: 'og:locale',
        },
      ],
    };
  },
};
</script>
